import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LanguageIcon from "@material-ui/icons/Language";
import { Avatar } from "@material-ui/core";
import GitHubIcon from "@material-ui/icons/GitHub";

class Resume extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
  }
  render() {
    return (
      <>
        <main className="main">
          <section className="non-centered-panel">
            <div className="container">
              <div id="resume">
                <div id="resume-info">
                  <p id="resume-name">MIGUEL DENIZ</p>
                  <p id="resume-occupation">Software Engineer</p>
                </div>
                <div id="resume-contact">
                  <div id="resume-contact-item">
                    <LinkedInIcon style={{ marginRight: "5px" }} />{" "}
                    <a
                      href="https://www.linkedin.com/in/miguel-deniz/"
                      style={{ color: "#42b0f5" }}
                    >
                      linkedin.com/in/miguel-deniz
                    </a>
                  </div>
                  <div id="resume-contact-item">
                    <LanguageIcon style={{ marginRight: "5px" }} />{" "}
                    <a
                      href="https://www.migueldeniz.com"
                      style={{ color: "#42b0f5" }}
                    >
                      migueldeniz.com
                    </a>
                  </div>
                  <div id="resume-contact-item">
                    <GitHubIcon style={{ marginRight: "5px" }} />{" "}
                    <a
                      href="https://github.com/Miguel-Deniz"
                      style={{ color: "#42b0f5" }}
                    >
                      github.com/Miguel-Deniz
                    </a>
                  </div>
                  <div id="resume-contact-item">
                    <LocationOnIcon style={{ marginRight: "5px" }} /> Phoenix,
                    AZ
                  </div>
                </div>

                <hr id="resume-hr" />

                <div id="resume-summary">
                  <Avatar
                    alt="Remy Sharp"
                    src="https://avatars.githubusercontent.com/u/8526826"
                    style={{
                      height: "100px",
                      width: "100px",
                      display: "inline-block",
                      filter: "grayscale(60%)"
                    }}
                  />
                  <div id="resume-summary-paragraph">
                    Experienced software engineer with a demonstrated history of
                    working in the tech industry. Lorem ipsum dolor sit amet,
                    commune accumsan persecuti nam ne, soleat omittam deseruisse
                    duo eu. Nec recteque constituto concludaturque id, omnis
                    feugiat ullamcorper an duo, vocent feugait periculis pri an.
                    Et duo dicunt persius voluptaria, vis in oblique corpora
                    voluptaria. Aeque vocibus eu qui. Vel in tempor lobortis, te
                    dicam delicata mel. Mea facilisi expetenda ut. Cum albucius
                    fabellas et, pro stet mandamus principes eu.
                  </div>
                </div>

                <hr id="resume-hr" />

                <div id="resume-work-experiences">
                  <div class="vertical-center-contents">
                    <div class="resume-section-heading"> </div>
                    <h2>Work Experiences</h2>
                  </div>
                  Software Engineer - American Express
                  <br />
                  Software Development Engineer - Amazon
                  <br />
                  Software Development Engineer Intern - Amazon
                </div>

                <hr id="resume-hr" />

                <div class="vertical-center-contents">
                  <div class="resume-section-heading"> </div>
                  <h2>Technical Skills</h2>
                </div>

                <div id="resume-technical-skills"></div>

                <hr id="resume-hr" />

                <div class="vertical-center-contents">
                  <div class="resume-section-heading"> </div>
                  <h2>Education</h2>
                </div>
                <div id="resume-education">
                  Arizona State University, ​Tempe, AZ — B​achelors in Computer
                  Science
                  <br />
                  January 2016 - December 2017 (2 yrs) ​3.76 GPA
                </div>

                <hr id="resume-hr" />

                <div class="vertical-center-contents">
                  <div class="resume-section-heading"> </div>
                  <h2>Projects</h2>
                </div>
                <div>
                  Experienced software engineer with a demonstrated history of
                  working in the tech industry. Lorem ipsum dolor sit amet,
                  commune accumsan persecuti nam ne, soleat omittam deseruisse
                  duo eu. Nec recteque constituto concludaturque id, omnis
                  feugiat ullamcorper an duo, vocent feugait periculis pri an.
                  Et duo dicunt persius voluptaria, vis in oblique corpora
                  voluptaria. Aeque vocibus eu qui. Vel in tempor lobortis, te
                  dicam delicata mel. Mea facilisi expetenda ut. Cum albucius
                  fabellas et, pro stet mandamus principes eu. Experienced
                  <br></br>
                  <br></br>
                  software engineer with a demonstrated history of working in
                  the tech industry. Lorem ipsum dolor sit amet, commune
                  accumsan persecuti nam ne, soleat omittam deseruisse duo eu.
                  Nec recteque constituto concludaturque id, omnis feugiat
                  ullamcorper an duo, vocent feugait periculis pri an. Et duo
                  dicunt persius voluptaria, vis in oblique corpora voluptaria.
                  Aeque vocibus eu qui. Vel in tempor lobortis, te dicam
                  delicata mel. Mea facilisi expetenda ut. Cum albucius fabellas
                  et, pro stet mandamus principes eu.
                  <br/><br/>
                  software engineer with a demonstrated history of working in
                  the tech industry. Lorem ipsum dolor sit amet, commune
                  accumsan persecuti nam ne, soleat omittam deseruisse duo eu.
                  Nec recteque constituto concludaturque id, omnis feugiat
                  ullamcorper an duo, vocent feugait periculis pri an. Et duo
                  dicunt persius voluptaria, vis in oblique corpora voluptaria.
                  Aeque vocibus eu qui. Vel in tempor lobortis, te dicam
                  delicata mel. Mea facilisi expetenda ut. Cum albucius fabellas
                  et, pro stet mandamus principes eu.
                  <br/><br/>
                  software engineer with a demonstrated history of working in
                  the tech industry. Lorem ipsum dolor sit amet, commune
                  accumsan persecuti nam ne, soleat omittam deseruisse duo eu.
                  Nec recteque constituto concludaturque id, omnis feugiat
                  ullamcorper an duo, vocent feugait periculis pri an. Et duo
                  dicunt persius voluptaria, vis in oblique corpora voluptaria.
                  Aeque vocibus eu qui. Vel in tempor lobortis, te dicam
                  delicata mel. Mea facilisi expetenda ut. Cum albucius fabellas
                  et, pro stet mandamus principes eu.
                </div>
              </div>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default Resume;

