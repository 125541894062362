import React from "react";
import NavigationBar from "./NavigationBar";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
  }
  render() {
    return (
      <>
        <main className="main">
          <NavigationBar></NavigationBar>

          <section className="panel">
            <div className="container">
              <h1 className="mainheading">Projects</h1>
              <hr></hr>

              <ul>
                <li>
                  <h2>Exoplanet Exploration - Coming soon</h2>
                </li>
                <li className="active">
                  <h2>Stock Martker Visuazlizer - Coming soon</h2>{" "}
                </li>
                <li className="active">
                  <h2>Chess Analysis - Coming soon</h2>{" "}
                </li>
                <li className="active">
                  <h2>Metrica - Coming soon</h2>{" "}
                </li>
                <li className="active">
                  <h2>Phillips Hue Lights App - Coming soon</h2>{" "}
                </li>
              </ul>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default Projects;
