import React from "react";
import "./App.css";
import Projects from "./Projects";
import Welcome from "./Welcome";
import Resume from "./Resume";
import Contact from "./Contact";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Articles from "./Articles";
import { Dimensions } from "react-native";
import ResumeAndroid from "./ResumeAndroid";
import NavigationBar from "./NavigationBar";


function App() {
  var deviceWidth = Dimensions.get('window').width;


    if (deviceWidth < 1000) {
      return <Router>
      <Switch>
      <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/resume">
          <ResumeAndroid />
        </Route>
        <Route path="/articles">
          <Articles/>
        </Route>
        <Route path="/projects">
          <Projects />
        </Route>
        <Route path="/">
          <Welcome />
        </Route>
      </Switch>
    </Router>
    }
    else return <Router>
      <NavigationBar></NavigationBar>
      <Switch>
      <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/resume">
          <Resume />
        </Route>
        <Route path="/articles">
          <Articles/>
        </Route>
        <Route path="/projects">
          <Projects />
        </Route>
        <Route path="/">
          <Welcome />
        </Route>
      </Switch>
    </Router>
}

export default App;
