import React from "react";

class NavigationBar extends React.Component {
  constructor(props) {
    super(props);
    
    const fullpathname = window.location.pathname //returns the current url minus the domain name
    const pathnamearray = fullpathname.split("/");
    const pathname = pathnamearray[1];

    var isWelcome = false;
    var isProjects = false;
    var isArticles = false;
    var isContact = false;
    var isResume = false;

    switch(pathname) {
      case "":
        isWelcome = true;
        break;
      case "projects":
        isProjects = true;
        break;
      case "articles":
        isArticles = true;
        break;
      case "contact":
        isContact = true;
        break;
      case "resume":
        isResume = true;
        break;
      default:
        isWelcome = true;
    }
    this.state = {
      welcomeActive: isWelcome,
      projectsActive: isProjects,
      articlesActive: isArticles,
      contactActive: isContact,
      resumeActive: isResume
    };
  }


  render() {
    const { welcomeActive, projectsActive, articlesActive, resumeActive, contactActive } = this.state

    return (
      <>
        <main className="main">
          <aside className="sidebar">
            <nav className="nav">
              <ul>
                <li className={welcomeActive ? "active" : ""}>
                  <a href="/">Welcome</a>
                </li>
                <li className={projectsActive ? "active" : ""}>
                  <a href="/projects">Projects</a>
                </li>
                <li className={articlesActive ? "active" : ""}>
                  <a href="/articles">Articles</a>
                </li>
              </ul>
            </nav>
          </aside>
        </main>
      </>
    );
  }
}

export default NavigationBar;
