import React from "react";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
  }
  render() {
    return (
      <>
        <main className="main">
          <section className="panel">
            <div className="container">
              <h1 className="mainheading">Contact</h1>
              <hr></hr>

              <h1>Hi, i'm Miguel Deniz</h1>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default Contact;
