import React from "react";
import { BrowserRouter as Switch, Route } from "react-router-dom";

class Articles extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
  }
  render() {
    return (
      <>
        <main className="main">
          <section className="panel">
            <div className="container">
              <h1 className="mainheading">Articles</h1>
              <hr></hr>

              <Switch>
                <Route exact path="/articles">
                  <h3 className="mainheading">
                    <a href="/articles/hue-lights">Create a Phillips Hue Lights App</a>
                  </h3>
                  <h3 className="mainheading">
                    <a href="/articles/arduino">Create an Aruduino Robot</a>
                  </h3>
                </Route>
                <Route path="/articles/hue-lights">
                  <h1 className="mainheading">Phillips Hue Lights App</h1>

                  
                </Route>
                <Route path="/articles/arduino">
                  <h1 className="mainheading">Aruduino Tutorial</h1>
                </Route>
              </Switch>
            </div>
          </section>
        </main>
      </>
    );
  }
}

export default Articles;
