import React from "react";
import Avatar from "@material-ui/core/Avatar";

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
  }

  render() {
    return (
      <>
        <div className="star"></div>
        <section className="panel">
          <div className="container">
            <h1 className="mainheading">Miguel Deniz</h1>
            <hr></hr>
            <h1 className="subheading">Software Engineer</h1>

            <div id="welcome-summary">
              <Avatar
                alt="Remy Sharp"
                src="https://avatars.githubusercontent.com/u/8526826"
                style={{
                  height: "150px",
                  width: "150px",
                  borderRadius: "100%",
                  marginRight: "30px",
                  filter: "grayscale(30%)"
                }}
              />

              <p>
                I'm Miguel. A software engineer based out of Phoenix, Arizona.
                I have spent the last 5 years working in top tech companies and
                I enjoy learning and building new things.
              </p>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Welcome;
